<template>
  <div>
    <header-view></header-view>
    <div class="tab">
      <div class="d-flex">
        <div
          class="tab-item d-flex justify-center cursor"
          @click="tabIndex(1)"
          :class="{ tab_active: type == 1 }"
        >
          全部
        </div>
        <div
          class="tab-item d-flex justify-center cursor"
          @click="tabIndex(3)"
          :class="{ tab_active: type == 3 }"
        >
          待支付
        </div>
        <div
          class="tab-item d-flex justify-center cursor"
          @click="tabIndex(2)"
          :class="{ tab_active: type == 2 }"
        >
          已支付
        </div>
      </div>
    </div>
    <div class="order">
      <div class="order-item" v-for="(item, index) in list" :key="index">
        <div class="order-top">
          <div class="left">订单号：{{ item.orderNum }}</div>
          <div class="item-active" v-if="item.payStatus == 1">待支付</div>
          <div class="item-actives" v-if="item.payStatus == 0">已支付</div>
        </div>
        <div class="order-content">
          <div class="left"><img :src="item.courseIcon" alt="" /></div>
          <div class="right">
            <div class="title">{{ item.courseName }}</div>
            <div class="time">{{ item.createTime }}</div>
              <div class="price">￥{{ item.payPrice }}</div>

            <div
              class="btn"
              @click="goOrder(item)"
              v-if="item.canPayStatus == 1"
            >
              去支付
            </div>
          </div>
        </div>
      </div>
      <el-pagination
        :page-size="pageSize"
        :pager-count="pageNum"
        layout="prev, pager, next"
        :total="total"
        style="text-align: center"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <footer-view></footer-view>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialog"
      :before-close="cancel"
       width="80%"
    >
      <div class="buy">
        <div
          class="buy-item"
          @click="selectBuy(item.dictValue)"
          :class="{ 'buy-active': buyIndex == item.dictValue }"
          v-for="(item, index) in payType"
          :key="index"
        >
          {{ item.remark }}
        </div>
      </div>
      <div class="buycen">
        <div class="buycen-wx" v-if="buyIndex == 1">
          <div id="qrcode" ref="qrcode"></div>
          <div class="btn-wx" @click="changeFinish">我已支付完成</div>
        </div>
        <div class="buycen-wx" v-if="buyIndex == 2">
          <div class="buycen-title">点击下方确认按钮，前往支付宝收银台</div>
        </div>
        <div class="buycen-dui" v-if="buyIndex == 3">
          <el-input
            placeholder="请输入兑换码"
            v-model="exchangeCode"
          ></el-input>
        </div>
      </div>
      <div class="dialog-footer">
        <div class="price">
          {{ detailInfo.payPrice }}
        </div>
        <div class="btn" @click="submit">确定</div>
        <!-- <el-button @click="cancel">取消</el-button> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import HeaderView from "@/components/HeaderView";
import FooterView from "@/components/FooterView";
import { myOrders, toPay } from "@/api/user";
import QRCode from "qrcodejs2";
import {
  payTypes,
  queryOrder,
} from "@/api/details";
export default {
  components: {
    HeaderView,
    FooterView,
  },
  data() {
    return {
      pageNum: 1,
      pageSize: 5,
      total: 0,
      type: 1,
      list: "",
      dialog: false,
      dialogTitle: "支付方式",
      payType: [],
      buyIndex: "",
      detailInfo: "",
      exchangeCode: "",
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getDetail();
    },
    // 获取支付方式
    changeBuy() {
      payTypes()
        .then((res) => {
          console.log(res);
          this.payType = res.data;
          this.buyIndex = res.data[0].dictValue;
          if (res.data[0].dictValue == 1) {
            this.changeGetpay();
          }
          this.dialog = true;
        })
        .catch(() => {});
    },
    tabIndex(id) {
      if (this.type != id) {
        this.type = id;
        this.pageNum = 1;
        this.getDetail();
      }
    },
    getDetail() {
      myOrders({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        type: this.type,
      })
        .then((res) => {
          console.log(res);
          this.total = res.data.total;
          this.list = res.data.list;
        })
        .catch(() => {});
    },
    goOrder(item) {
      this.detailInfo = item;
      this.dialog = true;
      this.changeBuy();
    },
    selectBuy(index) {
      this.buyIndex = index;
      if (this.buyIndex == 1) {
        this.changeGetpay();
      } else {
        const codeHtml = document.getElementById("qrcode");
        codeHtml.innerHTML = "";
      }
    },
    cancel() {
      this.dialog = false;
      const codeHtml = document.getElementById("qrcode");
      codeHtml.innerHTML = "";
    },
    // 查看支付结果
    changeFinish() {
      queryOrder(this.orderNum)
        .then((res) => {
          console.log(typeof res.data.success);
          if (res.data.success == "true") {
            this.$message({
              message: "支付完成！",
              type: "success",
            });
            this.dialog = false;
            this.getlist();
            this.getDetail();
          } else {
            this.$message({
              message: "支付未完成！",
              type: "error",
            });
          }
        })
        .catch(() => {});
    },
    // 确定支付
    submit() {
      if (this.buyIndex == 1) {
        this.changeFinish();
      } else {
        toPay({
          orderNum: this.detailInfo.orderNum,
          exchangeCode: this.exchangeCode,
          payType: this.buyIndex,
        })
          .then((res) => {
            console.log(res);
            if (this.buyIndex == 2) {
              document.querySelector("body").innerHTML = res.data.body;
              const div = document.createElement("div"); // 创建div
              div.innerHTML = res.data.body; // 将返回的form 放入div
              document.body.appendChild(div);
              document.forms[0].submit();
            }
            if (this.buyIndex == 3) {
              if (res.data.success == "true") {
                this.$message({
                  message: "兑换成功！",
                  type: "success",
                });
                this.dialog = false;
                this.getlist();
                this.getDetail();
              } else {
                this.$message({
                  message: "兑换码不正确！",
                  type: "error",
                });
              }
            }
            if (this.buyIndex == 4) {
              if (res.data.success == "true") {
                this.$message({
                  message: "开通成功！",
                  type: "success",
                });
                this.dialog = false;
                this.getlist();
                this.getDetail();
              }
            }
          })
          .catch(() => {});
      }
    },
    // 微信支付
    changeGetpay() {
      toPay({
        orderNum: this.detailInfo.orderNum,
        exchangeCode: this.exchangeCode,
        payType: this.buyIndex,
      })
        .then((res) => {
          console.log(res);
          if (this.buyIndex == 1) {
            let qrcode = new QRCode("qrcode", {
              width: 150, // 二维码宽度
              height: 150, // 二维码高度
              text: res.data.code_url,
            });
            this.orderNum = res.data.orderNum;
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .tab {
    width: 100% !important;
  }
  .order {
    width: 100% !important;
    padding: 32px 10px 32px 10px !important;
  }
}
.kong {
  margin-top: 50px;
  .kong-img {
    height: 210px;
    width: 280px;
  }
  .kong-txt {
    font-size: 20px;
    color: #999999;
    margin-top: 30px;
  }
}
.tab_active {
  border-color: #5f2eff !important;
  color: #5f2eff !important;
}
.tab {
  width: 1170px;
  margin-right: auto;
  margin-left: auto;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.36);
  border-radius: 6px;
  padding-top: 36px;
  box-sizing: border-box;
  .tab-item {
    width: 120px;
    padding-bottom: 11px;
    box-sizing: border-box;
    border-bottom: 2px solid transparent;
    margin-left: 15px;
    font-size: 20px;
    color: #666666;
  }
}
.order {
  width: 1170px;
  margin-right: auto;
  margin-left: auto;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.36);
  border-radius: 6px;
  margin-top: 20px;
  padding: 32px 36px 32px 36px;
  box-sizing: border-box;
  .order-item {
    background: #ffffff;
    border-radius: 10px;
    border-bottom: 1px solid #eeeeee;
    box-shadow: 0px 0px 6px 0px rgba(191, 191, 191, 0.18);
    padding: 0 20px 20px 20px;
    box-sizing: border-box;
    margin-bottom: 20px;
    .order-top {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      box-sizing: border-box;
      border-bottom: 2px solid #f4f6fa;
      .item-active {
        font-size: 16px;
        color: #f53444;
      }
      .item-actives {
        font-size: 16px;
        color: #333333;
      }
    }
    .order-content {
      display: flex;
      width: 100%;
      .left {
        width: 140px;
        height: 140px;
        background: #eeeeee;
        border-radius: 6px;
        overflow: hidden;
        margin-top: 20px;
        margin-right: 20px;
      }
      .right {
        flex-shrink: 0;
        flex: 1;
        .title {
          font-size: 18px;
          font-weight: bold;
          color: #444444;
          margin: 20px 0;
        }
        .time {
            font-size: 16px;
            color: #777777;
          }
        .price {
          font-size: 30px;
          color: #ff6923;
          display: flex;
          justify-content: flex-end;
        }
        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          width: 102px;
          height: 34px;
          // background: linear-gradient(-17deg, #ed2e32 1%, #f8a927 100%);
          background: linear-gradient(90deg, #ffba13 0%, #fb283a 100%);
          border-radius: 17px;
          font-size: 16px;
          font-weight: normal;
          color: #ffffff;
          margin-left: auto;
        }
      }
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-top: 1px solid #e5e5e5;
  .price {
    color: #ff712a;
    font-size: 24px;
  }
  .btn {
    color: #fff;
    background-color: #0c9;
    border-color: #0c9;
    padding: 6px 20px;
    font-size: 14px;
    cursor: pointer;
  }
}
.buy {
  display: flex;
  border-top: 1px solid #e5e5e5;
  padding-top: 10px;
  .buy-item {
    width: 120px;
    height: 46px;
    margin-right: 10px;
    border: 1px dashed #d2d2d2;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .buy-active {
    border: 2px solid #f55f60;
    color: #f55f60;
  }
}
.buycen {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  .buycen-wx {
    display: flex;
    flex-direction: column;
    align-items: center;
    .btn-wx {
      color: #fff;
      background-color: #0c9;
      border-color: #0c9;
      padding: 6px 20px;
      font-size: 14px;
      cursor: pointer;
      margin-top: 10px;
    }
  }
  .buycen-title {
    font-size: 20px;
  }
}
</style>
